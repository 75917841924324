import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blag27/image1.jpg"
import image2 from "../../images/blag27/measurements.png"



export default function TrainingWithKettlebellsTheBasics(){
    return(
        <BlogPostOld
            src={image}
            title={"Training with Kettlebells: The Basics"}
            summary={"The kettlebell's  unique shape allows versatility in a small space. Which makes it an awesome tool for strength, conditioning, and rehab."}
            date={"Jan. 4, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <div>

                <p className={"text-center"}><strong>Brief Kettlebell history&nbsp;</strong></p>
                <p>Kettlebells (KB) entered the US market in the 90’s and were made popular by<a href="http://www.strongfirst.com/" target="_blank" rel="noopener"> Pavel Tsatsouline</a> a former Soviet Special Forces trainer who migrated from Belarus. Because it is simply a weight with a handle on it the origins can be traced back to tools used on farms and boats hundreds of years earlier.&nbsp;&nbsp;</p>
                <p className={"text-center"}><strong>How the weight works</strong></p>
                <p>The kettlebell's&nbsp; unique shape allows versatility in a small space. Which makes it an awesome tool for strength, conditioning, and rehab. Traditional Russian bells come in 11 sizes and are measured in KG (kilograms). They start at 8 kg (17.6 lbs) and progress into 48kg (105.3 lbs). In the Kettle bell community the 48 kg bell is affectionately known as the beast. Kettlebells increase by 4 kg or 8.8 lbs making each increase in weight meaningful to the user thus a great (KPI) key performance indicator of improvement in strength. Several American manufacturers produce smaller and larger bell sizes, with different handle options and are measured in lbs. Below is a table with traditional KB sizes and the American conversations in LBS.&nbsp;</p>
                <img src={image}/>
                <img src={image2}/>

                <p>I’m personally a traditionalist when it comes to kettlebells and stick with the Russian shapes and sizes. My bias comes into play as a large part of our rehab strategies is managing people who are injured or fearful of weight back to picking up load again as soon as possible. Many of my low back pain patients can deadlift a 106 lb kettlebell. This includes many of our youth athletes and seniors.&nbsp;</p>
                <p>Because the lightest KB is an 8 kg (17.6lbs) ball of iron this can challenge a person's perception of what they can lift and should lift. Oftentimes people believe they need to get stronger or strengthen an injured area, but also at the same time believe they need to limit the amount of weight they use to train with in fear of harming or damaging the area further. They understand they need to get stronger but also limit their capacity to do so by managing themselves away from load. HURT DOES NOT ALWAYS EQUAL HARM! These people may be living in exercise purgatory using bands and 5-10 lbs dumbbells.The belief that one should limit their load to an arbitrary number or not squat or deadlift in fear of injury is a “zombie belief.” You are not fragile, the human body is resilient and is meant to adapt and overcome the challenges we place on it.&nbsp;&nbsp;&nbsp;</p>
                <p>This is why at Kinetic Impact&nbsp; we recommend following a program that grows organically with you. It's not the weight you lift that hurts you, it's the weight you're not prepared to lift that poses the threat. Many times when people experience excessive soreness beyond what is expected it is a result of too much too soon: Too many reps, too much weight, too many days a week, or too many different exercises. This can be unmotivating, a hindrance to progress, and feel harmful even if it's not. The key to proper progression, and meaningful long term results involves communication and a partnership between coach/ trainer/clinician&nbsp; and patient/client. Consistency for a beginner has&nbsp; more value than intensity. Finding a safe entry point, with realistic goals and timelines will yield more long term results and is key to success.</p>
                </div>
            <div className={"lg:flex lg:flex-row lg:justify-center text-black"}>
                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                    <a className={"px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 text-black"}>Call Us and get started! </button></a>
                    <a className={"px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 text-black"}>Message us to learn more! </button></a>
                </div>
            </div>

        </BlogPostOld>

    )
}